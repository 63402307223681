<template>
  <div></div>
</template>
<script>
/*
 *  get code/access_token components
 *
 * */
import { mapActions, mapGetters } from 'vuex'
import { getParameterByName } from '@/utils/function-utils'

export default {
  name: 'SaaSAuthorize',
  computed: {
    ...mapGetters('saas', ['routePath']),
  },
  mounted() {
    const code = getParameterByName(this.$route.fullPath, 'code')
    this.setCode(code)
    this.$router.push({
      path: this.routePath,
    })
  },
  methods: {
    ...mapActions('saas', ['setCode']),
  },
}
</script>
